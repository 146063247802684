import cookies from '../../../../cookies';
import apiCell from '../../../../api/select/cell';
//import apiGene from '../../../../api/select/gene';

export default{
  data(){
    return {
      organismId: 1,
      lstCells: [],
      lstGeneO: [],
      lstGeneKO: [],
      lstGeneTag:[],
      geneType: 1,
      lstTags: [
        {text: 'GFP (Green Florence Protein)',value: 1},
        {text: 'mCherry',value: 2},
      ],
      tagId: 1

    }
  },
  props: ['isAdd'],
  methods:{
    cancelModal(){
      this.$bvModal.hide('modalEntity');
      this.resetModal();
    },
    resetModal(){
      this.organismId=1;
      this.organismChanged();
    },
    okModal(){
      //todo 
    },
    organismChanged(){
      //todo
    },
    readOrganisms(){
      var org=cookies.getStorage('organisms');
      var ret=[];
      for (var i=0;i<org.length;i++)
        ret.push({value: org[i].id,text: org[i].text});
      return ret;
    },
    searchCell(search, loading) {
      if (search==undefined || search.length<3)
          return;
      loading(true);
      apiCell.search(this.organismId,search).then(
        (res)=>{
          console.log(res.data.results);
          this.lstCells = res.data.results;
          loading(false);
      }).catch( (err)=>{
        alert('Error: ' + err);
        loading(false);
      });
    },
  }
}