import apiRead from '../../api/curation/readArticle.js'
import IgnoreModal from './IgnoreModal/IgnoreModal.vue';
import EntitiesPanel from './EntitiesPanel/EntitiesPanel.vue';
import Cookies from '../../cookies.js';

export default {
  components: {
    IgnoreModal,EntitiesPanel
  },
  data(){
    return {
      articleId: 99999,
      articleTitle: '',
      journal: '',
      authors: '',
      articleYear: 0,
      loading: true,
      keySentences: '',
      summary: '',
      cellLines:'',
      methods:''
    }
  },
  beforeMount(){
    if (this.$route.params.id==undefined || this.$route.params.id=='' || this.$route.params.id==null)
      this.articleId=0;
    else
      this.articleId = this.$route.params.id;
    this.loading = true;
    apiRead.readArticle(this.articleId).then( (res)=>{
        this.loading=false;
        this.articleId = res.data.articleId;
        this.articleTitle = res.data.title;
        this.journal = res.data.journalName;
        this.authors = res.data.authors;
        this.articleYear = res.data.year;
    }).catch( (err)=>{
      alert(err);
    });
  },
  methods:{
    ShowIgnoreModal(){
      this.$bvModal.show('modalIgnore');
    },
    readIgnoreTypes(){
      var igt = Cookies.getStorage('ignoreTypes');
      var ret =[];
      for (var i=0;i<igt.length;i++)
        ret.push({value: igt[i].id,text: igt[i].text, fields: igt[i].fields});
      return ret;
    },
    refreshProcess(newData){
      if (newData == null){
        this.keySentences = '';
        this.methods = '';
        this.summary = '';
        this.cellLines='';
      }else{
        this.keySentences = newData.keyParagraph;
        this.methods = newData.methods;
        this.summary = newData.summary;
        this.cellLines = newData.cellLines;
      }
    }
  }
}