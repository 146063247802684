import cookies from "../../../cookies";
import apiProcess from '../../../api/curation/process.js';
import EntityModal from './EntityModal/EntityModal.vue';

export default{
  data(){
    return {
      organismId: 1,
      loading: false,
      isEntityDialogAdd: false,
      entityDialogRowNo: 0,
      entities: [],
      tblFields: [
        {key:'cell',sortable:false,label: 'Cell'},
        {key:'genes',sortable:false,label: 'Genes/Tag'},
        {key:'actions',sortable:false,label: 'Actions'},
      ]
    }
  },
  props: ['articleId'],
  events: ['refreshData'],
  components: {
    EntityModal
  },
  created(){
    if (this.articleId>0)
      this.doProcess();
  },
  methods:{
    readOrganisms(){
      var org=cookies.getStorage('organisms');
      var ret=[];
      for (var i=0;i<org.length;i++)
        ret.push({value: org[i].id,text: org[i].text});
      return ret;
    },
    resetEntities(){
      this.doProcess(true,true);
    },
    processEntities(){
      this.doProcess(false,false);
    },
    addEntities(){
      this.doProcess(false,true);
    },
    doProcess(reset = true,add=true){
      this.loading = true;
      if (reset)
        this.entities = [];
      apiProcess.process(this.articleId,this.organismId).then((res)=>{
        this.keySentences= res.data.keyParagraph;
        this.summary = res.data.summary;
        this.cellLines = res.data.cellLines;
        this.methods = res.data.methods;
        if (add){
          console.log(res.data.entities[0]);
          for (var i=0;i<res.data.entities.length;i++)
            this.entities.push({
                cell: res.data.entities[i].cellName,
                genes: res.data.entities[i].KOGeneNames.join(', '),
                actions: ''
            });
        }
        this.$emit('refreshData',res.data);
        this.loading=false;
      }).catch(()=>{
        this.$emit('refreshData',null);
          this.loading=false;
          alert('Unexpected error.\nPlease check your internet connection and try again later.');
      })
    },
    showAddDialog(){
      this.isEntityDialogAdd = true;
      this.$bvModal.show('modalEntity');
    },
    showEditDialog(row){
      this.isEntityDialogAdd=false;
      this.entityDialogRowNo = row;
      this.$bvModal.show('modalEntity');
    }
  }
}