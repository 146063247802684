export default{
  props: ['articleId','ignoreTypes'],
  data(){
    return {
      igType: 1,
      fieldsList: []
    }
  },
  methods:{
    cancelModal(){
      this.$bvModal.hide('modalIgnore');
      this.resetModal();
    },
    resetModal(){
      this.igType=1;
      this.typeChange();
    },
    okModal(){
      //todo 
    },
    typeChange(){
      var nam = this.getTypeFields().split(';');
      var r = []
      for (var i=0;i<nam.length;i++)
        if (nam[i].length>0)
          r.push({name: nam[i],value:''});      
      this.fieldsList = r;
    },
    getTypeFields(){
      for (var i=0;i<this.ignoreTypes.length;i++){
          if (this.ignoreTypes[i].value==this.igType){
              if (this.ignoreTypes[i].fields=='') break;
              return this.ignoreTypes[i].fields;
          }
      }
      return 'Comment';
    }
  }
}